// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
// import { NavActionType } from 'config';
// import { handlerCustomerDialog } from 'api/customer';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  PlusOutlined,
  TeamOutlined,
  ScheduleOutlined,
  LinkOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  PlusOutlined,
  TeamOutlined,
  ScheduleOutlined,
  LinkOutlined
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'chat',
      title: <FormattedMessage id="chat" />,
      type: 'item',
      url: '/apps/chat',
      icon: icons.MessageOutlined,
      breadcrumbs: false
    },
    {
      id: 'calendar',
      title: <FormattedMessage id="calendar" />,
      type: 'item',
      url: '/apps/calendar',
      icon: icons.CalendarOutlined
    },
    {
      id: 'career',
      title: <FormattedMessage id="career" />,
      url: '/apps/career/list',
      type: 'collapse',
      icon: icons.FileTextOutlined,
      breadcrumbs: false,
      accessLevel: ['owner', 'manager', 'staff'],
      children: [
        {
          id: 'invoice-create',
          title: <FormattedMessage id="create" />,
          type: 'item',
          url: '/apps/career/create',
          accessLevel: ['owner', 'manager'],
          breadcrumbs: false
        },
        {
          id: 'invoice-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/career/list',

          breadcrumbs: false
        }
      ]
    },
    {
      id: 'housing',
      title: <FormattedMessage id="housing" />,
      type: 'item',
      icon: icons.ScheduleOutlined,
      url: '/apps/housing/list',
      isAdmin: true,
      breadcrumbs: false
    },
    {
      id: 'team',
      title: <FormattedMessage id="team" />,
      type: 'item',
      icon: icons.TeamOutlined,
      url: '/apps/team',
      accessLevel: ['owner', 'manager'],
      breadcrumbs: false
    },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      url: '/apps/customer/customer-list',
      type: 'item',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: false,
      isAdmin: true,
    },    
    {
      id: 'invoice',
      title: <FormattedMessage id="invoice" />,
      url: '/apps/invoice/dashboard',
      type: 'item',
      icon: icons.FileTextOutlined,
      breadcrumbs: false,
      isAdmin: true,
    },
    {
      id: 'support',
      title: <FormattedMessage id="support" />,
      type: 'item',
      url: '/support/list',
      icon: icons.CustomerServiceOutlined,
      isAdmin: true,
      breadcrumbs: false
    },       
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic',
    //       breadcrumbs: false
    //     }
    //   ]
    // },
  ]
};

export default applications;
