import axios from 'axios';
// import { logger } from 'utils/common';

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL ,
  headers: {
    "Content-Type": "application/json"
  },
});

// Function to fetch client IP address
const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return 'Unknown';
  }
};

const getGeoLocation = async () => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve(`${latitude},${longitude}`);
        },
        (error) => {
          console.error('Error fetching GeoLocation:', error);
          resolve('Unknown');
        }
      );
    } else {
      resolve('Geolocation not supported');
    }
  });
};

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('serviceToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    const [ip, geoLocation] = await Promise.all([getIpAddress(), getGeoLocation()]);


    config.headers['X-Client-IP'] = ip;
    config.headers['X-Client-GeoLocation'] = geoLocation;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      // Redirect to the login page with the correct site URL
      // window.location = `${window.location.origin}/login`;
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (args) => {
  const [url, body, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, body, { ...config });

  return res.data;
};

export const fetcherPut = async (args) => {
  const [url, body, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.put(url, body, { ...config });

  return res.data;
};

export const fetcherDelete = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.delete(url, { ...config });

  return res.data;
};

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}[\]\\/+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, attributes = {}) => {
  // logger.info('Setting cookie:', name, 'Value:', value, 'Attributes:', attributes);
  const cookieAttributes = {
    path: '/',
    ...attributes
  };

  if (cookieAttributes.expires instanceof Date) {
    cookieAttributes.expires = cookieAttributes.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  for (let key in cookieAttributes) {
    updatedCookie += `; ${key}`;
    if (cookieAttributes[key] !== true) {
      updatedCookie += `=${cookieAttributes[key]}`;
    }
  }

  // logger.info('Updated cookie string:', updatedCookie);
  document.cookie = updatedCookie;
};

export const clearTokens = () => {
  // logger.info('Clearing tokens (accessToken, refreshToken, user).');
  setCookie('accessToken', '', { path: '/', expires: new Date(0) });
  setCookie('refreshToken', '', { path: '/', expires: new Date(0) });
  setCookie('user', '', { path: '/', expires: new Date(0) });
};
