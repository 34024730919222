import { lazy } from 'react';

// project import
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import SimpleLayout from 'layout/Simple';
import { SimpleLayoutType } from 'config';
import ProtectedRoute from './ProtectedRoute';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

// render - widget
const WidgetStatistics = Loadable(lazy(() => import('pages/widget/statistics')));
const WidgetData = Loadable(lazy(() => import('pages/widget/data')));
const WidgetChart = Loadable(lazy(() => import('pages/widget/chart')));

// render - applications
const AppChat = Loadable(lazy(() => import('pages/apps/chat')));
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));

const AppKanban = Loadable(lazy(() => import('pages/apps/kanban')));
const AppKanbanBacklogs = Loadable(lazy(() => import('sections/apps/kanban/Backlogs')));
const AppKanbanBoard = Loadable(lazy(() => import('sections/apps/kanban/Board')));

const AppCareerList = Loadable(lazy(() => import('pages/apps/career/list')));
const AppApplicantList = Loadable(lazy(() => import('pages/apps/career/applicants')));
const AppCareerAdd = Loadable(lazy(() => import('sections/apps/career/FormAdd')));
const AppCareerEdit = Loadable(lazy(() => import('sections/apps/career/Edit')));

const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')));

const AppInvoiceCreate = Loadable(lazy(() => import('pages/apps/invoice/create')));
const AppInvoiceDashboard = Loadable(lazy(() => import('pages/apps/invoice/dashboard')));
const AppInvoiceList = Loadable(lazy(() => import('pages/apps/invoice/list')));
const AppInvoiceDetails = Loadable(lazy(() => import('pages/apps/invoice/details')));
const AppInvoiceEdit = Loadable(lazy(() => import('pages/apps/invoice/edit')));

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));

const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const SubscriptionTermsPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const SubscriptionReviewPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const CartPage = Loadable(lazy(() => import('pages/extra-pages/payment')));

const CheckoutPage = Loadable(lazy(() => import('pages/extra-pages/payment')));
const PaymentConfirmationPage = Loadable(lazy(() => import('pages/extra-pages/payment')));
const PaymentDetailsPage = Loadable(lazy(() => import('pages/extra-pages/payment')));

// Support
const AppSupportHome = Loadable(lazy(() => import('pages/apps/support/main')));
const AppSupportList = Loadable(lazy(() => import('pages/apps/support/list')));
const AppSupportAdd = Loadable(lazy(() => import('sections/apps/support/FormCustomerAdd')));
const AppSupportEdit = Loadable(lazy(() => import('sections/apps/support/FormCustomerAdd')));

// Support
const AppMobileAdsList = Loadable(lazy(() => import('pages/apps/ads/mobile/list')));
const AppMobileAdsAdd = Loadable(lazy(() => import('sections/apps/ads/mobile/FormCustomerAdd')));
const AppMobileAdsEdit = Loadable(lazy(() => import('sections/apps/ads/mobile/FormCustomerAdd')));

// Support
const AppDirectAdsList = Loadable(lazy(() => import('pages/apps/ads/direct/list')));
const AppDirectAdsAdd = Loadable(lazy(() => import('sections/apps/ads/direct/FormCustomerAdd')));
const AppDirectAdsEdit = Loadable(lazy(() => import('sections/apps/ads/direct/FormCustomerAdd')));

// Team
const AppTeamList = Loadable(lazy(() => import('pages/apps/team/list')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: '/support',
          errorElement: <ErrorBoundary />,
          element: <ProtectedRoute accessLevel="Admin" />, // Wrap entire section with ProtectedRoute
          children: [
            {
              path: 'list',
              element: <AppSupportList />,
              errorElement: <ErrorBoundary />
            },
            {
              path: ':id',
              element: <AppSupportEdit />,
              errorElement: <ErrorBoundary />
            }
          ]
        },
        {
          path: '/support',
          errorElement: <ErrorBoundary />,
          children: [
            {
              path: '',
              element: <AppSupportHome />,
              errorElement: <ErrorBoundary />
            },
            {
              path: 'create',
              element: <AppSupportAdd />,
              errorElement: <ErrorBoundary /> // Added consistency for error handling
            }
          ]
        },
        {
          path: '/subscription',
          errorElement: <ErrorBoundary />,
          element: <ProtectedRoute accessLevel={['owner', 'manager']} />, // Wrap entire section
          children: [
            {
              path: '',
              element: <PricingPage />,
              errorElement: <ErrorBoundary />
            },
            {
              path: 'terms',
              element: <SubscriptionTermsPage />, // Step to agree to terms before checkout
              errorElement: <ErrorBoundary />
            },
            {
              path: 'review',
              element: <SubscriptionReviewPage />, // Explicit review page to confirm details
              errorElement: <ErrorBoundary />
            },
            {
              path: 'cart',
              element: <CartPage />,
              errorElement: <ErrorBoundary />
            },
            {
              path: 'checkout',
              element: <CheckoutPage />,
              errorElement: <ErrorBoundary />
            },
            {
              path: 'confirmation',
              element: <PaymentConfirmationPage />, // Confirmation step post-payment
              errorElement: <ErrorBoundary />
            },
            {
              path: ':id',
              element: <PaymentDetailsPage />,
              errorElement: <ErrorBoundary />
            }
          ]
        },
        {
          path: 'dashboard',
          children: [
            {
              path: '',
              element: <WidgetStatistics />
            },
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            },
            {
              path: 'data',
              element: <WidgetData />
            },
            {
              path: 'chart',
              element: <WidgetChart />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'chat',
              element: <AppChat />
            },
            {
              path: 'calendar',
              element: <AppCalendar />
            },
            {
              path: 'kanban',
              element: <AppKanban />,
              children: [
                {
                  path: 'backlogs',
                  element: <AppKanbanBacklogs />
                },
                {
                  path: 'board',
                  element: <AppKanbanBoard />
                }
              ]
            },
            {
              path: 'customer',
              element: <ProtectedRoute accessLevel="Admin" />, // Wrap entire section
              children: [
                {
                  path: 'customer-list',
                  errorElement: <ErrorBoundary />,
                  element: <AppCustomerList />
                }
              ]
            },
            {
              path: 'ads/mobile',
              element: <ProtectedRoute accessLevel={['owner', 'manager']} />, // Wrap entire section with ProtectedRoute
              children: [
                {
                  path: 'create',
                  element: <AppMobileAdsAdd />
                },
                {
                  path: 'list',
                  element: <AppMobileAdsList />
                },
                {
                  path: ':id',
                  errorElement: <ErrorBoundary />,
                  element: <AppMobileAdsEdit />
                },
                {
                  path: ':id/analytics',
                  errorElement: <ErrorBoundary />,
                  element: <AppApplicantList />
                }
              ]
            },
            {
              path: 'ads/direct',
              element: <ProtectedRoute accessLevel={['owner', 'manager']} />,
              children: [
                {
                  path: 'create',
                  element: <AppDirectAdsAdd />
                },
                {
                  path: 'list',
                  element: <AppDirectAdsList />
                },
                {
                  path: ':id',
                  errorElement: <ErrorBoundary />,
                  element: <AppDirectAdsEdit />
                },
                {
                  path: ':id/applicants',
                  errorElement: <ErrorBoundary />,
                  element: <AppDirectAdsEdit />
                }
              ]
            },
            {
              path: 'team',
              element: <ProtectedRoute accessLevel={['owner', 'manager']} />,
              children: [
                {
                  path: '',
                  element: <AppTeamList />
                }
              ]
            },
            {
              path: 'career',
              children: [
                {
                  path: 'create',
                  element: <AppCareerAdd />
                },
                {
                  path: 'list',
                  element: <AppCareerList />
                },
                {
                  path: ':id',
                  errorElement: <ErrorBoundary />,
                  element: <AppCareerEdit />
                },
                {
                  path: ':id/applicants',
                  errorElement: <ErrorBoundary />,
                  element: <AppApplicantList />
                }
              ]
            },
            {
              path: 'invoice',
              element: <ProtectedRoute accessLevel="Admin" />,
              children: [
                {
                  path: 'dashboard',
                  element: <AppInvoiceDashboard />
                },
                {
                  path: 'create',
                  element: <AppInvoiceCreate />
                },
                {
                  path: 'details/:id',
                  element: <AppInvoiceDetails />
                },
                {
                  path: 'edit/:id',
                  element: <AppInvoiceEdit />
                },
                {
                  path: 'list',
                  element: <AppInvoiceList />
                }
              ]
            },
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    }
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    },
    {
      path: '*',
      element: <MaintenanceError />
    }
  ]
};

export default MainRoutes;
