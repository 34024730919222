// project import
import analytics from './analytics';
import applications from './applications';
import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import samplePage from './sample-page';
import other from './other';
// import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //
// items: [widget, applications, formsTables, chartsMap, samplePage, pages, other]

const menuItems = {
  items: [analytics, widget, applications, other]
};

export default menuItems;
