// ProtectedRoute.js
import PropTypes from 'prop-types';
import {
  //  Navigate,
  Outlet
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const ProtectedRoute = ({ accessLevel }) => {
  const { isAdmin, user } = useAuth(); // Fetch user role & isAdmin flag

  // Allow full access for admins
  if (isAdmin || user?.isAdmin) return <Outlet />;

  // Ensure user object and accessLevel exist before checking
  const userAccess = user?.accessLevel?.toLowerCase() || '';

  // Normalize accessLevel prop to lowercase (handles both string & array)
  const normalizedAccessLevel = Array.isArray(accessLevel) ? accessLevel.map((level) => level.toLowerCase()) : accessLevel.toLowerCase();

  // Check if user's accessLevel is allowed
  const hasAccess = Array.isArray(normalizedAccessLevel)
    ? normalizedAccessLevel.includes(userAccess)
    : userAccess === normalizedAccessLevel;

  return hasAccess ? <Outlet /> : null;
};

ProtectedRoute.propTypes = {
  accessLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default ProtectedRoute;
