import React from 'react';
import { useTheme } from '@mui/material/styles';

const CustomSVGIcon = () => {
  const theme = useTheme();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="129"
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" stroke="none">
        {/* First Path with theme primary.dark color */}
        <path
          d="M4745 10174 c-22 -2 -112 -11 -200 -20 -284 -27 -662 -98 -955 -179 -161 -44 -338 -104 -595 -200 l-205 -78 0 -122 c0 -382 79 -889 201 -1295 22 -74 72 -215 110 -312 l71 -178 31 17 c39 20 446 246 504 280 l42 24 -36 97 c-85 235 -166 487 -202 631 -41 163 -78 408 -64 421 29 27 463 146 708 194 303 60 493 78 875 83 372 5 515 -2 780 -38 287 -40 623 -117 875 -202 99 -33 100 -34 103 -68 6 -53 -26 -252 -63 -394 -35 -135 -105 -348 -196 -593 -37 -101 -50 -148 -43 -158 13 -22 527 -325 536 -316 12 12 186 496 234 652 124 398 178 728 186 1130 l3 144 -70 29 c-556 225 -1065 358 -1640 428 -151 18 -242 22 -565 24 -212 2 -403 1 -425 -1z"
          fill={theme.palette.primary.dark}
        />
        {/* Second Path with linear gradient */}
        <path
          d="M7957 8251 c-118 -42 -218 -79 -221 -82 -3 -3 143 -118 325 -255 329 -248 330 -249 302 -261 -15 -7 -143 -56 -283 -109 -315 -119 -696 -277 -1115 -464 -176 -78 -326 -144 -334 -147 -8 -4 26 47 76 113 101 134 227 316 267 385 l26 46 -355 208 c-196 114 -360 204 -364 199 -4 -5 -46 -67 -93 -139 -205 -309 -522 -653 -1022 -1108 l-119 -108 -29 28 c-49 46 -332 357 -452 497 -262 305 -377 462 -602 824 l-15 22 -42 -21 c-47 -24 -670 -369 -676 -375 -6 -6 82 -155 184 -309 186 -284 427 -585 763 -954 97 -106 201 -220 230 -251 l52 -58 -287 -173 c-407 -243 -732 -445 -919 -571 -314 -210 -733 -533 -1004 -774 -210 -186 -639 -618 -765 -769 -394 -475 -643 -902 -769 -1319 -54 -176 -66 -255 -55 -366 24 -254 101 -474 240 -685 115 -174 349 -380 541 -476 96 -48 285 -118 324 -120 11 0 14 -3 6 -6 -7 -2 -61 9 -120 27 -565 166 -979 674 -1028 1261 -6 70 -9 79 -18 58 -6 -14 -10 -129 -11 -294 0 -248 2 -279 23 -375 103 -457 350 -841 687 -1065 619 -410 1456 -318 2248 247 181 129 292 223 466 393 423 415 627 688 860 1152 204 408 303 738 396 1313 26 166 55 379 55 411 0 18 -10 19 -323 19 l-324 0 -12 -117 c-35 -331 -139 -816 -221 -1028 -88 -227 -240 -521 -373 -720 -181 -273 -504 -610 -807 -846 -257 -200 -548 -348 -814 -414 -102 -26 -119 -27 -270 -22 -183 5 -247 19 -374 78 -290 134 -473 403 -522 766 -18 131 -8 409 19 532 77 349 283 726 627 1147 367 449 885 933 1362 1272 205 145 594 394 1062 679 l235 144 6 -298 c3 -164 7 -429 8 -589 l1 -291 31 -7 c17 -3 204 -6 415 -6 l384 0 0 788 0 788 203 94 c111 51 380 179 597 283 1022 490 1333 634 1675 777 225 93 583 230 603 230 10 0 11 -21 7 -87 -8 -130 -26 -675 -22 -679 1 -2 90 -4 196 -6 l194 -3 185 495 185 495 181 74 c421 172 486 202 542 254 34 31 65 72 85 112 28 58 31 71 27 139 -3 64 -8 81 -34 118 -56 78 -170 128 -290 128 -51 0 -205 -39 -497 -125 -119 -36 -223 -65 -231 -65 -7 0 -157 70 -332 156 -176 86 -369 180 -430 209 l-111 53 -216 -77z"
          fill="url(#gradient)"
        />
      </g>
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="100%">
          <stop offset="0%" stopColor={theme.palette.primary.main} />
          <stop offset="100%" stopColor={theme.palette.primary.light} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CustomSVGIcon;
