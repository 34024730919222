// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  ToolOutlined,
  FundViewOutlined,
  BorderOutlined,
  BoxPlotOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ToolOutlined,
  FundViewOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  type: 'group',
  children: [
    {
      id: 'sub',
      accessLevel: ['owner'],
      title: <FormattedMessage id="sub" />,
      type: 'item',
      url: '/subscription',
      icon: icons.FundViewOutlined,
      chip: {
        label: 'upgrade',
        color: 'secondary',
        size: 'small'
      }
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/apps/profiles/account/settings',
      icon: icons.ToolOutlined
    }
  ]
};

export default other;
