// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { StopOutlined, AreaChartOutlined, LineChartOutlined, IdcardOutlined, DatabaseOutlined, LayoutOutlined } from '@ant-design/icons';

// icons
const icons = {
  AreaChartOutlined,
  LineChartOutlined,
  IdcardOutlined,
  LayoutOutlined,
  StopOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget = {
  id: 'analytics',
  title: <FormattedMessage id="analytics" />,
  icon: icons.IdcardOutlined,
  type: 'group',
  accessLevel: ['owner', 'manager', 'staff'],
  children: [
    {
      id: 'statistics',
      title: <FormattedMessage id="analytics.overview" />,
      type: 'item',
      url: '/dashboard/',
      accessLevel: ['owner', 'manager', 'staff'],
      helpTitle: 'Direct Message Ads',
      icon: icons.AreaChartOutlined
    }
  ]
};

export default widget;
