import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';

// utils
import { fetcher } from 'utils/axios';

export const endpoints = {
  key: 'v1/notification',
  list: '/list', // server URL
  delete: '/delete' // server URL
};

export function useGetNotifications() {
  const { data, isLoading, error, isValidating } = useSWR(endpoints.key, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  // Memoize the return value for optimization
  const memoizedValue = useMemo(
    () => ({
      notification: data?.notifications,
      notificationLoading: isLoading,
      notificationError: error,
      notificationValidating: isValidating,
      notificationEmpty: !isLoading && !data?.notification?.length
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// Function to manually update the cache for notifications
export function updateNotificationsCache(newData) {
  mutate(endpoints.key, { notification: newData }, false); // Update cache without revalidation
}

// Function to clear the cache (optional)
export function clearNotificationsCache() {
  mutate(endpoints.key, null, false); // Clear cache without revalidation
}

export async function deleteNotification(customerId) {
  // to update local state based on key
  mutate(
    endpoints.key + endpoints.list,
    (currentCustomer) => {
      const nonDeletedCustomer = currentCustomer.customers.filter((customer) => customer.id !== customerId);

      return {
        ...currentCustomer,
        customers: nonDeletedCustomer
      };
    },
    false
  );

  // to hit server
  // you may need to refetch latest data after server hit and based on your logic
  //   const data = { customerId };
  //   await axios.post(endpoints.key + endpoints.delete, data);
}
